import { Helmet } from "react-helmet";

export function GlobalHead() {
  return (
    <Helmet>
      <meta
        name="facebook-domain-verification"
        content="eippbx9te3hrmpxncx00x37of0wzi3"
      />
    </Helmet>
  );
}
