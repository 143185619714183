exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-categories-business-tsx": () => import("./../../../src/pages/categories/business.tsx" /* webpackChunkName: "component---src-pages-categories-business-tsx" */),
  "component---src-pages-categories-customer-support-tsx": () => import("./../../../src/pages/categories/customer-support.tsx" /* webpackChunkName: "component---src-pages-categories-customer-support-tsx" */),
  "component---src-pages-categories-design-tsx": () => import("./../../../src/pages/categories/design.tsx" /* webpackChunkName: "component---src-pages-categories-design-tsx" */),
  "component---src-pages-categories-digital-marketing-tsx": () => import("./../../../src/pages/categories/digital-marketing.tsx" /* webpackChunkName: "component---src-pages-categories-digital-marketing-tsx" */),
  "component---src-pages-categories-human-resource-tsx": () => import("./../../../src/pages/categories/human-resource.tsx" /* webpackChunkName: "component---src-pages-categories-human-resource-tsx" */),
  "component---src-pages-categories-legal-tsx": () => import("./../../../src/pages/categories/legal.tsx" /* webpackChunkName: "component---src-pages-categories-legal-tsx" */),
  "component---src-pages-categories-software-engineering-tsx": () => import("./../../../src/pages/categories/software-engineering.tsx" /* webpackChunkName: "component---src-pages-categories-software-engineering-tsx" */),
  "component---src-pages-checkout-error-tsx": () => import("./../../../src/pages/checkout-error.tsx" /* webpackChunkName: "component---src-pages-checkout-error-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-choose-category-tsx": () => import("./../../../src/pages/choose-category.tsx" /* webpackChunkName: "component---src-pages-choose-category-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-index-tsx": () => import("./../../../src/pages/landing/index.tsx" /* webpackChunkName: "component---src-pages-landing-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-secret-survey-tsx": () => import("./../../../src/pages/secret-survey.tsx" /* webpackChunkName: "component---src-pages-secret-survey-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */)
}

